require("bootstrap")

// Clear any datatables session storage
for (let n = sessionStorage.length-1; n >= 0 ; n--) {
  let key = sessionStorage.key(n);
  if (/DataTables_/.test(key)) {
    sessionStorage.removeItem(key);
  }
}

// Clear mute/unmute session storage
sessionStorage.removeItem('alertsMuted');